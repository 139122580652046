ul.processProgress {
  margin: 0 auto;
  padding: 1em 0 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  top: calc(5rem * 0.05);
  top: calc(var(--header-height) * 0.05);
  right: 0;
  width: 100%;
  z-index: 9999;
  position: relative;
  align-items: baseline;
}

@media (min-width: 900px) {
  ul.processProgress > li {
    display: inline-block;
    margin: 0;
    color: white; /* for IE*/;
    color: var(--brand-reverse-color);
    background-color: #ff830077;
    height: 3rem;
    line-height: 3rem;
    padding: 0 2rem 0 2rem;
    position: relative;
  }

  ul.processProgress > li:first-of-type {
    padding-left: 1rem;
  }

  ul.processProgress > li::before {
    /*justify-items: center;*/
    content: attr(data-label);
    /* display: none; */
    margin: 0 0.5em 0 0;
    vertical-align: middle;
  }

  ul.processProgress > li > .index {
    display: none;
  }

  ul.processProgress > li > .label {
    height: 3rem;
    display: inline-block;
    vertical-align: middle;
  }

  ul.processProgress > li::after {
    display: inline-block;
    width: 2.1rem;
    height: 2.1rem;
    content: " ";
    right: -0.6rem;
    vertical-align: middle;
    border-color: #fff #fff transparent transparent;
    border-style: solid;
    border-width: 1.1rem;
    transform: rotate(45deg);
    position: absolute;
    top: -.7rem;
  }

  ul.processProgress > li.active, ul.processProgress > li.completed {
    font-weight: bold;
    background-color: #ff8300; /* for IE*/
    background-color: var(--brand-main-color);
    cursor: pointer;
  }

  ul.processProgress > li.stepTransition {
    width: auto;
    background-color: transparent;
    transform: none !important;
    box-shadow: none;
    vertical-align: middle;
    /* margin-left: 1rem; */
    padding: 0 1rem;
    quotes: "«" "»";
    color: #ff8300; /* for IE*/
    color: var(--brand-main-color)
  }

  ul.processProgress > li.stepTransition::before, ul.processProgress > li.stepTransition::after {
    display: none;
  }

  ul.processProgress > li:last-of-type {
    opacity: 1;
    width: 4rem;
    background-color: #ffffffff;
    min-width: -moz-fit-content;
    min-width: fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    border-radius: 0.2rem;
    background-image: url(./../../assets/images/osper-card-in-envelope.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 1.5em 0 0 0;
    margin-top: -1.5em;
    background-position: bottom;
  }


}

/* -------- mobile view ---------*/

@media (max-width: 899px) {
  ul.processProgress {
    height: 7rem;
  }
  ul.processProgress > li {
    width: 4em;
    height: 1em;
    position: relative;
    color: white; /* for IE*/;
    color: var(--brand-reverse-color)
  }

  ul.processProgress > li > .index, ul.processProgress > li > .label {
    display: none;
  }

  ul.processProgress > li.active > .index, ul.processProgress > li.active > .label {
    display: block;
    position: absolute;
    top: 2em;
    left: -2em;
    width: 10rem;
    height: 4rem;
    border-radius: 0;
    background-color: #ff8300; /* for IE*/
    background-color: var(--brand-main-color);
    padding: 0 1rem;
    line-height: 2.5rem;
    font-weight: lighter;
  }



  ul.processProgress > li.active > .label {
    top: 3.3rem;
    background-color: transparent;
    font-weight: bold;
  }

  ul.processProgress > li::before {
    display: inline-block;
    content: " ";
    width: 0.5em;
    height: 0.5em;
    border-radius: 0.5em;
    background-color: #ff8300; /* for IE*/
    background-color: var(--brand-main-color);
  }

  ul.processProgress > li > .index, ul.processProgress > li > .label {
    display: none;
  }

  ul.processProgress > li.active > .index::before {
display: block;
    position: absolute;
    top: -0.7em;
    left: 1.7em;
    content: " ";
    border-left: 0.5em solid transparent;
    border-right: 0.5em solid transparent;
    border-bottom: #ff8300; /* for IE*/
    border-bottom: 0.7em solid var(--brand-main-color);
  }

  ul.processProgress > .stepTransition, ul.processProgress > li:last-of-type {
    display:none;
  }
}
@media (max-width: 450px) {

   ul.processProgress > li.active > .index, ul.processProgress > li.active > .label {
    width: 6rem;

   }}

ul.processProgress > li:last-of-type::after {
  display: none;
}

ul.processProgress > li:nth-child(1) {
  z-index: 10;
}

ul.processProgress > li:nth-child(2) {
  z-index: 9;
}

ul.processProgress > li:nth-child(3) {
  z-index: 8;
}

ul.processProgress > li:nth-child(4) {
  z-index: 7;
}

ul.processProgress > li:nth-child(5) {
  z-index: 6;
}

ul.processProgress > li:nth-child(6) {
  z-index: 5;
}

ul.processProgress > li:nth-child(7) {
  z-index: 4;
}

ul.processProgress > li:nth-child(8) {
  z-index: 3;
}

ul.processProgress > li:nth-child(9) {
  z-index: 2;
}
