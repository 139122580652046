#root {
  position: relative;
  min-height: 100vh;
}

.errorScreen {
    margin: 10vw 25vh;
    background-color: var(--brand-main-color);
    color: var(--brand-reverse-color);
    padding: 5vw;
}
.errorScreen small{
  opacity: 0.3;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.template {
  display: none !important;
}

h4 {
  margin: 1rem;
  font-size: 2rem;
  font-weight: 100;
}

.screen-subtitle {
  margin: 1rem;
  font-size: 1rem;
  font-weight: 200;
  margin-bottom: 1rem;
  font-style: italic;
}

.form {
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.form > li {
  width: 100%;
  margin: 1.5rem 4rem 1rem 7rem;
}

.form > li.separator {
  width: 100%;
  height: 1px;
  margin: 1rem;
  padding: 0;
  border-top: 1px solid lightgray;
}

.form input, .form button {
  width: 100%;
  height: 2em;
  border: 1px solid #aaaaaa;
  border-radius: 0.5rem;
}

.form input[type="checkbox"] {
  width: auto;
  vertical-align: middle;
}

.form .btn-group {
  width: 100%;
  display: flex;
}

.form .btn-group > button {
  width: auto;
}

.input {
  margin-top: -0.3em;
}


.form label {
  color: #808080;
  margin: 0;
  padding: 0;
}

.card {
  width: 100%;
  height: 100%;
  padding-top: 54%;
  background-color: #cccccc;
  color: #666666;
  border: 1px outset #efefef;
  border-radius: 0.7rem;
}

.card.back {
  background-color: #cccccc;
  color: #666666;
}

.card.front {
  background-color: #ff8300;
  color: white;
}

.card.front.green {
  background-color: #00CC00;
}

.card.front.blue {
  background-color: #00b3ee;
}

.card span {
  width: 100%;
  margin: 0 1rem;
  color: white;
  position: relative;
  top: -4rem;
}

.vertical-input-group > *:first-child {
  padding-bottom: 0;
}

.vertical-input-group > *:first-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.vertical-input-group > *:last-child {
  padding-top: 0;
}

.vertical-input-group > *:last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.vertical-input-group > *:not(:last-child):not(:first-child) {
  padding-top: 0;
  padding-bottom: 0;
}

.vertical-input-group > *:not(:last-child):not(:first-child) * {
  border-radius: 0;
}

.vertical-input-group > *:not(:first-child) * {
  border-top: 0;
}

.MuiTypography-root {
    margin: 0.5em 0;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #ff8300 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #ff8300 !important;
}

.help-block {
  background: #dd4e22;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  display: block !important;
}

.address-container{
  margin: 5px auto 0 !important;
}

ul.address-selection {
  height: 15em;
  padding: 2px 0;
  font-size: inherit;
  overflow-y: scroll;
  width: 98%;
  z-index: 99999 !important;
  overflow-x: hidden;
  background-color: rgba(0, 0, 0, 0.17);
  width: 150%;
  margin-top: -8px;
  margin-left: 4px;
}

@media (max-width: 600px) {
  ul.address-selection {
    width: 100%;
  }
}

ul.address-selection li {
  padding: 12px 16px;
  border-bottom: 1px solid #eeeeee;
  overflow: hidden;
  text-overflow: ellipsis;
}

ul.address-selection li.cta {
  color: #ff8300;
  font-weight: bold;
}

ul.address-selection li:not(.placeholder) {
  cursor: pointer;
}

ul.address-selection li:hover {
  background-color: rgba(0, 0, 0, 0.27);
}

.error {
  color: rgb(244, 67, 54);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.39996px;
  line-height: 19.9167px;
  margin-bottom: 0px;
  margin-left: 11px;
  margin-right: 14px;
  margin-top: -3px;
  text-align: left
}
#postcode-helper-text{
  margin-bottom: 5px;
}
:root {
  --header-height: 5rem;
  --footer-height: 2rem;
  --brand-main-color: #ff8300;
  --brand-reverse-color: white;
  --main-background-color: white;
  --main-text-color: #555555;
  --reverse-background-color: #666666;
  --reverse-text-color: #bbbbbb;
  --success-background-color: #66B114;
}

.nomargin {
  margin: 0;
}

.input-text-field {
  width: '100%';
  margin-bottom: '20px';
  max-width: 500px;
}

.padDiv {
  padding: 0px 20px 20px 20px
}

.gifterArea{
  max-width: 100% !important;
}

.asterisk {
  color: red
}

.next-button-container {
  margin-top: 10px !important;
}

.select-design {
  color: #454d5c;
  margin: 10px 5px 5px 0;
  cursor: pointer;

}

.select-design:hover {
  color: #ff8300;

}

.help {
  color: #555555; /*for IE*/
  color: var(--main-text-color);
  font-style: italic;
  font-weight: 100;
}

.osper .MuiGrid-item, .osper .MuiGrid-spacing-xs-1 > .MuiGrid-spacing-xs-1 {
  /* margin: 0px auto !important; */
  padding-top: 0px;
}

a {
  color: rgb(69, 77, 92);
  text-decoration: underline;
}

a:hover,
a:focus {
  color: #cc6900;
  text-decoration: underline;
}

.center {
  background-color: white;
  margin: auto;
}

.container {
  /* background-color: white; */
  margin: auto;
  max-width: 650px;
  width: 100%;
}


.MuiTypography-body1 {
  color: #454d5c;
  margin: 10px 5px 5px 0;
  font-family: "Museo Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

/* .MuiPickersDay-daySelected:hover {
  background-color: #ff8300 !important;
}

.MuiPickersYear-yearSelected {
  color: #ff8300 !important;
} */

.referral-input-success {
  color: #66B114;
  margin: 0 !important;
}

.success #referral-code {
  background-image: url("./assets/images/tick_icon.png");
  background-position: 98% center;
  background-repeat: no-repeat;
  background-size: 23px auto;
}

.fail #referral-code {
  background-image: url("./assets/images/cross_icon.png");
  background-position: 98% center;
  background-repeat: no-repeat;
  background-size: 23px auto;
}

.checkbox .MuiFormControlLabel-root {
  display: flex;
  width: 100%;

}

.MuiBackdrop-root{
  z-index: 9999 !important;
}
#dialog .MuiBackdrop-root{
  z-index: -1 !important;
}

.checkbox .MuiCheckbox-root {
  width: 10%;
  align-self: flex-start;
  padding: 0;
  margin: 2px -8px 0 -8px;
}

.checkbox .MuiTypography-body1 {
  width: 90%;
}

@media (max-width: 585px) {
  .checkbox .MuiCheckbox-root {
    width: 10%;
    align-self: flex-start;
    padding: 0;
    margin: 2px -1px 0 -1px;
  }
}

.MuiFormHelperText-root {
  font-size: 0.85rem;
}

.MuiFormHelperText-root.Mui-error {
  font-family: "Museo Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

input:disabled ~ .makeStyles-icon-19 {
  opacity: 0.3;
}

.osper .MuiPaper-elevation1 {
  border: none;
  box-shadow: none;
}

.osper .MuiFilledInput-root {
  background-color: unset;
  border: 1px solid #ccc;
  border-radius: 6px;
  height: 3rem;
  font-size: 2rem;
  padding: 10px;
  line-height: 1.33;
  color: #555555;

}

.osper .MuiInputBase-input {
  font-family: "Museo Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  color: #555555;
}


.MuiFilledInput-underline:before {
  border-bottom: none !important;
}

.osper .MuiFilledInput-underline:after {
  border-bottom: #ff8300;
  border-bottom: 2px solid var(--brand-main-color);
}

.errorMessage {
  color: #f44336;
}

.MuiButton-contained {
  transition: all 300ms !important;
}

.MuiButton-contained:hover {
  background-color: #cc6900 !important;

  box-shadow: none !important;

  border-color: #cc6900 !important;
}

.MuiFilledInput-input {
  padding: 7px 12px !important;
  height: 18px;
}

.MuiFilledInput-input {
  height: 24px;
}


.MuiFilledInput-input {
  height: 18px;
}

.MuiFilledInput-underline:after {
  content: none !important;
}

.MuiFormHelperText-root.Mui-error {
  top: 35px;
}

.MuiFilledInput-root.Mui-focused {
  border: 1px solid #ff8300 !important;
}

.MuiFilledInput-root.Mui-error {
  border: 1px solid #D0021B !important;
}

.MuiFilledInput-underline:after {
  content: none !important;
}

.braintree-large-button{
  display: none;
}

/* overriding the default styles for autocompleted fields */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #444444;
  transition: background-color 1s ease-in-out 1s;
  background-color: transparent !important;
  border-radius: 5px;
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill{
 box-shadow: 0 0 4px 40px #ffffffff inset;
}
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus{
  box-shadow: 0 0 4px 40px #e2e2e2 inset;
}
#marketingLabel {
  align-items: flex-start !important;

}
.contentWrapper {
    max-width: 650px;
    width: 100%;
    margin: 0 auto !important;
}

#marketingLabel .jss6{
  padding-top: 2px;
}

.osper .MuiCheckbox-root {
  padding: 0 9px;
}

.activate{
  display: block;
  background-color: var(--success-background-color);
  background-color: #66B114; /*IE*/;
  color: white;
  padding: 2px 10px;
max-width: 160px;
justify-self: flex-end;
text-decoration: none;
border-radius: 8px;
}

.parentCard{
  margin-top: 10px;
}
@media screen and (max-width: 960px){
  .activate{

  max-width: 100%;
  text-align: center;
  }
}
  .parentCard a:hover{
  color: white !important;
  text-decoration: none !important;
}
/* .parentCard:hover .activate{
  display : block;
} */

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}


.subHead{
  margin: 1rem;
}

a{
  position: relative;
}

a:active:after {
  content:attr(title);
  padding:5px;
  border:1px solid grey;
  width: auto;
  top:20px;
  color: grey;
  /* right:10%; */
  background: #fff;
  position: absolute;
  white-space:nowrap;
  max-width: 250px;
  font-size: 13px;
}

.iconTick{
  margin-right: 10px;
}

.payButton{
  margin: auto !important;
}



