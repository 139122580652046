.postcode-component {
display: flex;
}
.postcode-component .MuiInputBase-root{
  border-radius: 0.3em 0 0 0.3em
}
.postcode-component .MuiButtonBase-root{
  border-radius: 0 0.3em 0.3em 0;
  height: 3.35em;
  margin: 4px 0 0px 0;
  border: 0px solid black;
}

@media (max-width: 600px) {
  .postcode-component .MuiButtonBase-root{
    margin: 0px 0 0px 0;
  }
}

