.btn-fb {
  color: #fff;
  background-color: #3b5998;
  border-color: transparent;
}
.btn-fb:hover,
.btn-fb:focus,
.btn-fb:active,
.btn-fb.active,
.open .dropdown-toggle.btn-fb {
  color: #fff;
  background-color: #30487b;
  border-color: rgba(0, 0, 0, 0);
}
.btn-fb:active,
.btn-fb.active,
.open .dropdown-toggle.btn-fb {
  background-image: none;
}
.btn-fb.disabled,
.btn-fb[disabled],
fieldset[disabled] .btn-fb,
.btn-fb.disabled:hover,
.btn-fb[disabled]:hover,
fieldset[disabled] .btn-fb:hover,
.btn-fb.disabled:focus,
.btn-fb[disabled]:focus,
fieldset[disabled] .btn-fb:focus,
.btn-fb.disabled:active,
.btn-fb[disabled]:active,
fieldset[disabled] .btn-fb:active,
.btn-fb.disabled.active,
.btn-fb[disabled].active,
fieldset[disabled] .btn-fb.active {
  background-color: #3b5998;
  border-color: transparent;
}


.module,
.module-flex {
  position: relative;
  padding: 84px 0 50px 0;
}
.module.module-tight,
.module-flex.module-tight {
  padding: 0;
}
.module.module-quite-tight,
.module-flex.module-quite-tight {
  padding: 30px 0 30px;
}
.module.module-tight-menu,
.module-flex.module-tight-menu {
  padding-bottom: 10px;
}
.module .links-bar,
.module-flex .links-bar {
  padding: 0;
}
.module .links-bar .pad,
.module-flex .links-bar .pad {
  padding: 20px 0;
  transition: all 300ms;
}
.module .links-bar .pad:hover,
.module-flex .links-bar .pad:hover {
  background-color: #000000;
}
.module .links-bar a,
.module-flex .links-bar a {
  color: #fff;
  text-decoration: none;
  padding: 20px 0;
}
.module .links-bar .line-icon,
.module-flex .links-bar .line-icon {
  padding: 10px;
  opacity: 0.9;
}
.module,


/* Module specific styling */
.module__title {
  /* color: #fff; */
  padding: 15px;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}
.module__title--orange {
  background: #ff8300;
}
.module__title--green {
  background: #66B114;
}
.module__title--darkblue {
  background: #00527f;
}
.module-orange {
  background: #ff8300;
  color: #fff;
}
.module-orange .order-content-header a {
  color: #fff;
}
.module-orange .order-content-header a:hover {
  color: #fff;
}
.module-orange .order-content-header p {
  margin: 0;
  max-width: 500px;
  margin: auto;
}
.module-orange p a {
  text-decoration: underline;
  color: #fff;
}
.module-san-hero {
  /* background-image: url(/assets/img/santander/mobile-hero-bg.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
}
.module-san-hero .container {
  padding-top: 50px;
}
.module-white {
  background: #fff;
  color: #333;
}
.module-white .module-next-arrow {
  color: #bbb;
}
.module-white .module-next-arrow:hover {
  color: #bbb;
  text-decoration: none;
}
.module-white p a {
  text-decoration: underline;
  color: #333;
}
.module-green {
  background: #66B114;
  color: #fff;
}
.module-green--text {
  background: #fff;
}
.module-green--text h3 {
  color: #66B114;
}
.module-green p a {
  color: #fff;
  text-decoration: underline;
}
.module-yellow {
  background: #f4bd0c;
  color: #fff;
}
.module-yellow p a {
  text-decoration: underline;
  color: #fff;
}
.module-darkblue {
  background: #00527f;
  color: #fff;
}
.module-darkblue p a {
  text-decoration: underline;
  color: #fff;
}
.module-turquoise {
  background: #00a5a0;
  color: #fff;
}
.module-turquoise p a {
  text-decoration: underline;
  color: #fff;
}
.module-warmdarkgrey {
  background: #302e2b;
  color: #fff;
}
.module-warmdarkgrey p a {
  text-decoration: underline;
  color: #fff;
}
.module-warmlightgrey {
  background: #ececec;
}
.module-warmlightgrey .module-next-arrow {
  color: #aaa;
}
.module-warmlightgrey .module-next-arrow:hover {
  color: #aaa;
}
.module-warmlightgrey p a {
  text-decoration: underline;
  color: #333;
}
.module-mediumgrey {
  background: #EEEBE8;
}
.module-mediumgrey .module-next-arrow {
  color: #aaa;
}
.module-mediumgrey .module-next-arrow:hover {
  color: #aaa;
}
.module-mediumgrey p a {
  text-decoration: underline;
  color: #333;
}
.module-red {
  background: #dd4e22;
  color: #fff;
}
.module-red .module-next-arrow {
  color: #fff;
}
.module-red .module-next-arrow:hover {
  color: #fff;
}
.module-red p a {
  text-decoration: underline;
  color: #fff;
}
.module-title span {
  font-weight: 300;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.module-deep {
  height: 70%;
}
.module-shallow {
  height: 40%;
}
.module-puddle {
  height: 30%;
}
.module-next-arrow {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  font-size: 30px;
  opacity: 0.5;
  transition: all 500ms;
}
.module-next-arrow:hover {
  color: #fff;
  opacity: 0.8;
  text-decoration: none;
}
.module-next-btn {
  position: absolute;
  bottom: 10px;
  text-align: center;
  margin: 0 auto;
}
.module-slim h4,
.module-slim h5 {
  line-height: 1.4;
  font-style: italic;
  font-weight: 300;
}
.module-background,
.module-sm-background {
  background-size: cover;
  background-position: 50% 50%;
}
.module-background-contain {
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.module-icon-inline-orange {
  color: #ff8300;
}
.module-w-navigation {
  transition: all 300ms;
}
.module-home-validation .avatar {
  border-radius: 1000px;
  max-width: 80px;
}


.btn-fb:hover, .btn-fb:focus, .btn-fb:active, .btn-fb.active, .open .dropdown-toggle.btn-fb {

  color: #fff;
  background-color: #30487b;
  border-color: rgba(0, 0, 0, 0);

}
.btn:hover, .btn:focus {

  color: #333;
  text-decoration: none;

}
.facebook a{
  text-decoration: none;
  color: white;

}
.facebook a:hover{
  color: white;
}
.facebook a:active{
  color: white;

}
.osper-share-dialog__share-button {

  margin-top: 10px;

}
.btn-fb {

  color: #fff;
  background-color: #3b5998;
  border-color: transparent;
  font-size: 18px;
   line-height: 20px;
    margin: 10px 0;
     padding: 15px 0;

}
.btn-curved {

  border-radius: 50px;
  padding-left: 15px;
  padding-right: 15px;
  white-space: normal;

}

.btn-inner-shadow {

  box-shadow: inset 0px -3px 0px 0px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.1);

}
.btn-block {

  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;

}
.btn {

  /* display: inline-block; */
  margin-bottom: 0;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  transition: all 300ms;
  box-shadow: none;
  -webkit-box-shadow: none;

}

.order-success .icon-tick, .nomargin{
  color: #66B114; /*for IE*/
color: var(--success-background-color);
}


