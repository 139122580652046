.expandable{
  transition: all 400ms;
}
.expandable.expand {
  min-height: 13em
}
@media screen and (max-width: 600px){
  .expandable.expand {
    min-height: 17em
  }
}
.expandable.collapse {
  height: 0px;
  overflow: hidden
}


