
.app-header>*{
  display:block;
  width:100%;
  height: 5rem; /*for IE*/
  height:var(--header-height);
  transition: height ease 0.5s;
  overflow: hidden;
}

.app-header>.contents{
  display: flex;
  justify-content: space-between;
  background-color: #ff8300; /* for IE*/
  background-color: var(--brand-main-color);
  color: white; /* for IE*/;
  color: var(--brand-reverse-color);
  /*box-shadow: 0 0 8px var(--brand-main-color);*/
  z-index: 10;
  padding: calc(5rem * 0.30) calc(5rem * 0.15);
  padding: calc(var(--header-height) * 0.30) calc(var(--header-height) * 0.15);
  box-sizing: border-box;
}
.app-header>.success{
  background-color: #66B114; /* for IE*/
  background-color: var(--success-background-color);
  color: white; /* for IE*/;
  color: var(--main-background-color);
  box-shadow: 0 0 8px var(--success-background-color);
  z-index: 1000;
}

.app-header .logo, .app-header .logo>img{
  display: block;
  height: calc(5rem * 0.40);
  height: calc(var(--header-height) * 0.40);
}
.app-header .logo>img {
  margin:0;
}
.app-footer{
  /* to avoid horizontal scroll*/
  width: 99vw;
  height: 2rem; /*for IE*/
  height: var(--footer-height);
}
.app-footer>*{
  display: block;
  width: 100%;
  height: 2rem; /*for IE*/
  height: var(--footer-height);
  transition: height ease 0.5s;
  overflow: hidden;
}
.app-footer>.contents{
  position: absolute;
  bottom:0;
  left:0;
  background-color: #666666 /* for IE*/;
  background-color: var(--reverse-background-color);
  color: #bbbbbb; /*for IE*/
  color: var(--reverse-text-color);
  z-index: 1000;
  height: 2rem; /*for IE*/
  height: var(--footer-height);
  /* Left and right padding cuases horizontal scroll so chnge it to margin for inner contents*/
  padding: 0.5em 0;
}
.footer_content{
  margin:0 0.5em;
}
.logoutBtn {
    display: block;
    color: white;
    text-decoration: none;
    border: 1px solid white;
    border-radius: 1em;
    height: 2em;
    line-height: 2em;
    padding: 0 1em;
    cursor: pointer;
}
.affiliate{
  display: none;
}
.affiliate a {
  color:white;
}
.affiliate.heading {
  background: none;
  height:auto;
  width: 96vw;
  margin: 1em auto;
  padding: 1em;
}
.MSE .affiliate.heading{
  display: block;
  background-color: #273156;
}

.finder .affiliate.heading{
  display: block;
  background-color: #0271e1;
}
