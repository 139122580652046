.shareButtons {
  display: flex;
  justify-content: space-between;
  padding:2em;
}
.shareButtons>* {
  width:3em;
  height:3em;
  border-radius: 1.5em;
  border:1px solid silver;
  line-height: 3.6em;
}
