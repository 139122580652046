.activate-container {
    height: 96vh;
    background: #ff8300;
    font-family: "Museo Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.activate-logo {
    display: flex;
    background: #ff8300;
    padding: 38px 28px;
    color: inherit;
    text-decoration: none;
}

.activate-logo img {
    height: 44px;
    border: 1px solid #ff8300;
}

.activate-header {
    display: flex;
    background: #ff8300;
}

.activate-content {
    display: flex;
    flex-direction: column;
    width: 30%;
    background: #ff8300;
    align-items: flex-start;
}

.failed-content {
    display: flex;
    flex-direction: column;
    width: 60%;
    background: #ff8300;
    align-items: flex-start;
}

.activate-flex-row-center h1 {
    font-size: 54px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 12px;
}

.activate-flex-row-center p {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    line-height: 28px;
    text-align: left;
    margin: 0;
    text-wrap: pretty;
}

.activate-flex-row-center {
    display: flex;
    justify-content: center;
    height: 80vh;
}

/* .activate-footer {
    display: flex;
    height: 30px;
    background: #666;
    padding: 13px 10px;
}

.activate-footer-content {
    color: #bbb;
    opacity: 0.7;
}

.activate-flex-row-center {
    display: flex;
    justify-content: center;
    height: 80vh;
} */