.gallery2 .galleryItems{
  position:absolute;
  width:98vw;
  left:1vw;
  background-color: white;
  flex-wrap: wrap;
  display: flex;
  /* Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.*/
  transform: translateZ(0);
  z-index: 999;
  justify-content: center;
}
.gallery2 .galleryContainer{
  margin-top: -40vw;
}
.preselectionTopSpacer{
  height:42vw;
}
@media screen and (min-width: 600px){
  .gallery2 .galleryContainer{
    margin-top: -22vw;
  }
  .preselectionTopSpacer{
    height:26vw;
  }
}
@media screen and (min-width: 992px){
  .gallery2 .galleryContainer{
    margin-top: -24vw;
  }
  .preselectionTopSpacer{
    height:26vw;
  }
}
@media screen and (min-width: 1280px){
  .gallery2 .galleryContainer{
    margin-top: -18vw;
  }
  .preselectionTopSpacer{
    height:20vw;
  }
}


.gallery2 .galleryBackdrop{
  position:relative;
  width:100%;
  left:0;
  display: flex;
  justify-content: center;
  z-index:0;
}
.gallery2  .galleryBackdrop span{
  margin-top:2em;
}
.gallery2 .galleryTile{
    width: 22vw;
    padding: 2vw 0 0 0;
    position: relative;
    overflow-clip: content;
    overflow: hidden;
    transition: opacity .5s;
    border-radius: 0.5rem;
    opacity: 1;
    margin: 1vw 2vw 0 2vw;
}
@media screen and (max-width: 899px){
 .gallery2  .galleryTile{
    width:30vw;
  }
}
@media screen and (max-width: 599px){
 .gallery2  .galleryTile{
    width:60vw;
  }
}
.gallery2 .galleryTile.hidden{
  display:none;
}
.gallery2 .galleryTile.fadeOut{
  opacity: 0.2;
  transition: opacity 0.5s;
  order:99;
  pointer-events: none;
  mix-blend-mode: luminosity;
}
.gallery2 .galleryTile.fadeIn{
  opacity: 1;
  transition: opacity 0.5s;
}

.gallery2  .childCardModule{
    display: flex;
    width: 100%;
    margin-top: 2em;
    margin-bottom: 70px;
}
.gallery2 .cardButton{
  cursor: pointer;
  color: #ff8300;
  color: var(--brand-main-color)
}
.gallery2 .cardButton:hover{
  text-decoration: underline;
}
/* touch devices with no "hover" events */
@media (hover: none) {
  .gallery2 .galleryTile .card-info {
    background-color: transparent;
    position: initial;
    margin-bottom: 1em;
    margin-top:-0.5em;
    box-shadow: none;
  }
  .gallery2 .galleryTile:hover .card-preview {
    bottom: 0 !important;
  }
}

.gallery2  .card-info {
    margin: 0;
    display: block;
    color: gray;
    font-size: 0.8rem;
    position: absolute;
    bottom: -100%;
    left: 0;
    background-color: #dddddd;
    width: 100%;
    transition: bottom 0.5s;
    line-height: 1.2em;
    box-shadow: 0 0 8px #00000022;
}
.gallery2 .galleryTile:hover .card-info {
  bottom:0;
}
@media only screen and (max-width: 600px) {
  .gallery2 .galleryTile .card-info {
    bottom:0;
  }
}

.gallery2 .galleryTile .card-preview {
  transition: bottom 0.5s;
  position: relative;
  bottom:0;
}
.gallery2 .galleryTile:hover .card-preview {
  bottom: 1em;
}
.gallery2 .card-info>* {
    display: block;
  margin:0 0 0 1em;
}
.gallery2 .card-info>*:first-of-type {
  margin-top:1em;
}
.gallery2 .card-info>*:last-of-type {
  margin-bottom:1em;
}
.gallery2 .card-info .design-name{
  font-weight: bold;
}
.gallery2 .card-preview-container{
  margin: 0;
  position: relative;
}
.gallery2 .galleryTile .card-preview-container{
  margin: 0;
  padding:1px;
}
.gallery2 .galleryTile .card-preview{
    border-radius: 0.5rem;
}
.gallery2 .card-preview{
    border-radius: 1rem;
    border:1px solid #dddddd;
    width: 100%;
    background-color: #f0f0f0;
    padding-bottom: 63.1%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 4px 4px 16px rgba(0,0,0,0.1);
    cursor: pointer;
    position: relative;
}
.gallery2 .card-preview *{
  color: white; /* for IE*/;
  color: var(--brand-reverse-color);
  padding:1%;
  position:absolute;
  top:45%;
}
.gallery2 .filters{
    display: flex;
    flex-wrap: wrap;
    transform: translateZ(0px);
    width: 100%;
    padding: 1em 1px 0 0;
    background-color: white;
    margin: 0;
    border-top: 1px solid #cccccc;
}
.gallery2 .filters>*{
  display: block;
  padding:2px 0.5em;
  color: #ff8300; /* for IE*/
  color: var(--brand-main-color);
  background-color: white; /* for IE*/;
  background-color: var(--brand-reverse-color);
  cursor: pointer;
}
.gallery2 .filters>b{
  color: #555555; /*for IE*/;
  color:var(--main-text-color)
}
.gallery2 .filters>li:hover{
  text-decoration: underline;
}
.gallery2 .filters>.enabled{
  background-color: #ff8300; /* for IE*/
  background-color: var(--brand-main-color);
  color: white; /* for IE*/;
  color: var(--brand-reverse-color);
}

.gallery2 .card-preview-overlay{
    background-image: url('./../../assets/images/card-chip-mastercard-overlay-001.png');
    border-radius: 1rem;
    width: 100%;
    padding-bottom: 63.1%;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top:0;
    left:0;
    pointer-events: none;
}
.gallery2 .galleryTile .card-preview-overlay{
  display: none;
}

.removeChild,.changeDesign {
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 3em;
    vertical-align: bottom;
    margin: -1em 0.5em 2em 0.5em;
    padding-top: 2.5em;
  text-align: center;
}
.removeChild {
  background-image: url('./../../assets/images/child-remove-icon.png');
}
.changeDesign {
  background-image: url('./../../assets/images/design-change-icon.png');
}



