


.gallery2 .childrenNames input:not([value=""]) {
  background-color: #ff8300;
  background-color: var(--brand-main-color);
  color: #fff;
  font-weight: bold;
  border-right: 1px solid white;
  border-radius: 0.5em 0.5em 0 0;
}
.gallery2 .childrenNames input[value=""] {
  border-right: 1px solid white;
  border-radius: 0.5em 0.5em 0 0;
}
.gallery2 .changeCard {
  background-color:#eeeeee;
  padding: 10px 20px;
}
.gallery2 .addAnotherChildModule{
  margin-top:2em;
}
.gallery2 .addAnotherChildModule .message{
  margin:1em;
}
.gallery2 .margin-top{
  margin-top: 10px;
}
.gallery2 .gridContainer .MuiGridList-root {
  margin-top: -50px !important;
  justify-content: left;
}
